import React,{useEffect} from 'react'
import img1 from '../images/control-system.png'
import { FaCircleCheck } from "react-icons/fa6";
import team from '../images/team.png'
import customer from '../images/customer.png'
import gratitute from '../images/gratitute.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

function Who() {

    useEffect(() => {
        Aos.init({duration: 2000})
    },[])

    return (
        <div id='who' className='lg:p-20 p-8 bg-gray-100 ' >
            <h1 className='text-2xl text-[#bf9b30] font-bold ' >Who we are </h1>
            <p className='text-xl font-medium my-4 lg:w-1/2 ' >Jaffry Travel is dedicated to offering exceptional <span className='text-[#bf9b30] font-bold' >Umrah & Hajj</span> experiences with a focus on comfort, convenience, and premium service.</p>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 my-12'>

                <div className='bg-gradient-to-r from-[#d7c694] to-green-300 hover:bg-gradient-to-t p-10 ' data-aos = 'fade-up' >
                    <img src={team} alt='' className='h-20 mx-auto' />
                    <h1 className='text-center text-2xl font-bold my-3'>Team</h1>
                    <ul className='text-sm my-4' >
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Experienced consultants.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Professional ground staff.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />24/7 support availability.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Expertise in Umrah services.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Dedicated service providers.</li>
                    </ul>
                </div>

                <div className='bg-gradient-to-l from-[#d7c694] to-green-300 hover:bg-gradient-to-b p-10  ' data-aos = 'fade-down' >
                    <img src={img1} alt='' className='h-20 mx-auto' />
                    <h1 className='text-center text-2xl font-bold my-3'>Services</h1>
                    <ul className='text-sm my-4' >
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Budget-friendly options.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Luxury travel experiences.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Tailored to preferences.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Best Umrah packages.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Inclusive offerings for all travelers.</li>
                    </ul>
                </div>

                <div className='bg-gradient-to-r from-[#d7c694] to-green-300 hover:bg-gradient-to-t p-10 ' data-aos = 'fade-up' >
                    <img src={customer} alt='' className='h-20 mx-auto' />
                    <h1 className='text-center text-2xl font-bold my-3'>Customer</h1>
                    <ul className='text-sm my-4' >
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Appreciation for customer trust.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Best travel experience.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Committed to future support.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Plan secured journey.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Aiming for excellence.</li>
                    </ul>
                </div>

                <div className='bg-gradient-to-l from-[#d7c694] to-green-300 hover:bg-gradient-to-b p-10 ' data-aos = 'fade-down' >
                    <img src={gratitute} alt='' className='h-20 mx-auto' />
                    <h1 className='text-center text-2xl font-bold my-3'>Gratitude</h1>
                    <ul className='text-sm my-4' >
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Experienced consultants.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Professional ground staff.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />24/7 support availability.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Expertise in Umrah services.</li>
                        <li className='flex my-2 gap-x-2 '><FaCircleCheck className='my-auto' />Dedicated service providers.</li>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default Who
