import React, { useEffect } from 'react'
import about_img from '../images/img3.jpg'
import about_img2 from '../images/img1.jpg'
import Aos from 'aos'
import 'aos/dist/aos.css'


function About() {

    useEffect(() => {
        Aos.init({duration: 2000})
    },[])

    return (
        <div id='about' className='h-screen w-screen flex lg:flex-row md:flex-col flex-col lg:p-20 lg:justify-around justify-center items-center'>
            <div className='lg:flex md:flex hidden h-screen justify-center items-center' data-aos = 'fade-right' >
                <img src={about_img} alt='' className='lg:h-3/4  lg:w-fit md:w-2/4 ' />
                <img src={about_img2} alt='' className='lg:h-2/4 lg:w-fit md:w-1/4 relative right-24  border-4 ' />
            </div>
            <div className='lg:w-1/2  flex flex-col gap-y-6 lg:p-0 p-8 ' data-aos = 'fade-left' >
                <h1 className='text-4xl font-bold text-[#bf9b30]'>About us</h1>
                <p className='text-xl lg:w-3/4'>Jaffry Travel is passionate about providing the best travel experiences for your <strong className='text-[#bf9b30]'>Umrah & Hajj </strong> tours.</p>
                <h2 className='border border-green-900 bg-green-900 text-white font-bold w-fit px-12 py-1.5 text-xl rounded-lg' >Vision</h2>
                <p className='lg:mr-20 text-gray-900' >To become the premier ground service provider for Umrah travelers, delivering accessible, flexible, and affordable high-quality travel solutions.</p>
                <h2 className='border border-green-900 bg-green-900 text-white font-bold w-fit px-12 py-1.5 text-xl rounded-lg' >Mission</h2>
                <p className='lg:mr-20 text-gray-900' >Jaffry Travel is dedicated to delivering personalized Umrah experiences, uniting Muslims worldwide. We ensure every journey is seamless, enjoyable, and exceeds expectations.</p>
            </div>
        </div>
    )
}

export default About
