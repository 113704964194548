import React from 'react'
import logo from '../images/jaffri_Logo.png'
import { BsTelephoneFill } from "react-icons/bs";
import { MdMarkEmailRead } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

function Footer() {
  return (
    <footer className="bg-gray-200 lg:px-20 md:px-20 px-8 pt-20 pb-10 mt-10 ">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <h1 className="max-w-lg text-xl font-semibold tracking-tight text-gray-800 xl:text-2xl ">Subscribe our newsletter to get update.</h1>

            <form action="https://api.web3forms.com/submit" method="POST" className="flex flex-col mx-auto mt-6 space-y-3 md:space-y-0 md:flex-row">
              <input type="hidden" name="access_key" value="e9cf1999-3636-4890-b27e-bf23ea51988e" />
              <input type="hidden" name="redirect" value="http://localhost:3000/thanks" />
              <input id="email" type="email" name='Email' className="px-4 py-2 text-gray-700 bg-white border rounded-md  focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300" placeholder="Email Address" />

              <button className="w-full px-6 py-2.5 text-sm font-medium tracking-wider text-white transition-colors duration-300 transform md:w-auto md:mx-4 focus:outline-none bg-gray-800 rounded-lg hover:bg-gray-700 focus:ring focus:ring-gray-300 focus:ring-opacity-80">
                Subscribe
              </button>
            </form>
          </div>

          <div>
            <p className="font-semibold text-gray-800 ">Quick Link</p>

            <div className="flex flex-col items-start mt-5 space-y-2">
              <a href="#about" className="text-gray-600 transition-colors duration-300 hover:underline hover:text-[#bf9b30] ">About</a>
              <a href="#who" className="text-gray-600 transition-colors duration-300 hover:underline hover:text-[#bf9b30] ">Who We Are</a>
              <a href="#services" className="text-gray-600 transition-colors duration-300 hover:underline hover:text-[#bf9b30] ">Our Services</a>
            </div>
          </div>

          <div>
            <p className="font-semibold text-gray-800 ">Contact</p>

            <div className="flex flex-col items-start mt-5 space-y-2">
              <a href="/public" className="text-gray-600 transition-colors duration-300 hover:underline flex gap-2 hover:text-[#bf9b30]"><BsTelephoneFill color='#bf9b30' size={20} className='my-auto' />+966 7424 379932</a>
              <a href="/" className="text-gray-600 transition-colors duration-300 hover:underline flex gap-2 hover:text-[#bf9b30]"><MdMarkEmailRead color='#bf9b30' size={20} className='my-auto' />jaffry.travel.umrah@gmail.com</a>
              <a href="/" className="text-gray-600 transition-colors duration-300 hover:underline flex gap-2 hover:text-[#bf9b30]"><FaLocationDot color='#bf9b30' size={20} className='my-auto' />Hussein AlArab Street, AlZahra, Makkah</a>
            </div>
          </div>
        </div>

        <hr className="my-6 border-gray-500 md:my-8" />

        <div className="flex items-center justify-between">
          <a href="/">
            <img className="w-auto h-28" src={logo} alt="" />
            <h1 className='font-bold' >Jaffry Travels & Tours</h1>
          </a>

          <div>
            <p>&copy; 2024 Jaffry Travels. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
