import React, { useEffect, useState } from 'react';
import Home from './pages/home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import Contact from './pages/contact';
import Footer from './components/footer';
import { CiSaveUp2 } from "react-icons/ci";

function App() {
  const [showscroll, setshowscroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 20) {
        setshowscroll(true);
      } else {
        setshowscroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router className='overflow-x-hidden'>
      <div className='fixed w-screen top-0 z-10'>
        <Navbar />
      </div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact/economy' element={<Contact packageName='economy' />} />
        <Route path='/contact/standard' element={<Contact packageName='standard' />} />
        <Route path='/contact/premium' element={<Contact packageName='premium' />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />

      <div 
        className={`w-fit p-3 rounded-full bg-[#bf9b30] fixed right-5 bottom-5 cursor-pointer ${showscroll ? 'inline-block transition-all duration-200 ' : 'hidden'}`} 
        onClick={() => window.scrollTo(0, 0)}
      >
        <CiSaveUp2 size={35} color='white' />
      </div>
    </Router>
  );
}

export default App;
