import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos'
import 'aos/dist/aos.css'



const PackageCard = ({ title, price, details, features, isPopular,RouteLink,AOS_ANIM }) => (

  <div data-aos = {AOS_ANIM} className={`flex flex-col items-center p-8 rounded-lg shadow-lg max-w-sm ${isPopular ? 'bg-gradient-to-br from-blue-100 via-orange-100 to-purple-100 border-8 border-orange-200 relative' : 'bg-slate-100'}`}>
    {isPopular && (
      <p className="mono text-sm absolute -top-4 bg-red-400 text-zinc-100 py-0.5 px-2 font-bold tracking-wider rounded">
        POPULAR
      </p>
    )}
    {isPopular && (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-20 h-20 absolute -top-11 -left-11 fill-red-400">
        <path fillRule="evenodd" d="M12.963 2.286a.75.75 0 00-1.071-.136 9.742 9.742 0 00-3.539 6.177A7.547 7.547 0 016.648 6.61a.75.75 0 00-1.152-.082A9 9 0 1015.68 4.534a7.46 7.46 0 01-2.717-2.248zM15.75 14.25a3.75 3.75 0 11-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 011.925-3.545 3.75 3.75 0 013.255 3.717z" clipRule="evenodd"></path>
      </svg>
    )}
    <div className="text-center">
      <h2 className={`font-extrabold text-3xl mb-2 ${isPopular ? 'text-black' : 'text-zinc-800'}`}>{title}</h2>
      {price && (
        <div className="flex flex-col items-center my-6">
          <p className="font-extrabold text-4xl">{price}</p>
        </div>
      )}
      {details && <p className="opacity-60 text-center mb-4">{details}</p>}
    </div>
    <div className="flex flex-col gap-1">
      {features.map((feature, index) => (
        <p key={index} className="flex items-center text-sm">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 mr-2">
            <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clipRule="evenodd"></path>
          </svg>
          { feature } 
        </p>
      ))}
    </div>
    <div className="flex justify-center mt-8">
      <Link to={RouteLink} className="px-4 py-2 border-green-800 border-2 hover:shadow-lg hover:bg-green-200 rounded-xl">Get Started</Link>
    </div>
  </div>

);

function Packages() {

  useEffect(() => {
    Aos.init({duration: 2000})
},[])

  return (
    <div id='packages' className='lg:p-20 p-8 flex flex-col gap-y-6 ' >
       <h1 className='text-2xl text-[#bf9b30] font-bold ' >Our packages </h1>
       <p className='text-xl font-medium mb-10 lg:w-1/2 ' >We offer affordable packages with premium services, making your <span className='text-[#bf9b30] font-bold' >Umrah & Hajj</span> tours convenient, comfortable, and worry-free.</p>
      <div className="grid lg:grid-cols-3 grid-cols-1 md:grid-cols-2 lg:gap-0 md:gap-8 gap-y-8 text-zinc-800">
        <PackageCard
          title="Economy"
          price="SAR. 3,000"
          details="14 Nights"
          features={[
            '7 Nights - BADAR ALMASA',
            '7 Nights - WARDA SAFA',
            'Visa',
            'Transportation',
            'Accommodation',
            'Flight ticket',
            'Ziyarat',
          ]}
          RouteLink='/contact/economy'
          AOS_ANIM='fade-up'
        />
        <PackageCard
          title="Standard"
          price="SAR. 3,300"
          details="14 Nights"
          features={[
            '7 Nights - NAWARA SHAMS',
            '7 Nights - SKY VIEW',
            'Visa',
            'Transportation',
            'Accommodation',
            'Flight ticket',
            'Ziyarat'
          ]}
          RouteLink='/contact/standard'
          AOS_ANIM='fade-down'
          isPopular
        />
        <PackageCard
          title="Premium"
          price="SAR. 4,200"
          details="14 Nights"
          features={[
            '7 Nights - AZKA ALSAFA 5',
            '7 Nights - TAIBAH MADINA 5',
            'Visa',
            'Transportation',
            'Accommodation',
            'Flight ticket',
            'Ziyarat',
            'Breakfast'
          ]}
          RouteLink='/contact/premium'
          AOS_ANIM='fade-up'
        />
      </div>
    </div>
  );
}

export default Packages;
