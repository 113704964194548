import React, { useState, useEffect, useRef } from 'react';

function Stat() {
    const [experience, setExperience] = useState(0);
    const [team, setTeam] = useState(0);
    const [customer, setCustomer] = useState(0);
    const [reviews, setReviews] = useState(0);

    const statRef = useRef(null);
    const [hasAnimated, setHasAnimated] = useState(false);

    const animateCountUp = (setter, target) => {
        let currentCount = 0;
        const increment = Math.ceil(target / 100);

        const interval = setInterval(() => {
            currentCount += increment;
            if (currentCount >= target) {
                clearInterval(interval);
                setter(target);
            } else {
                setter(currentCount);
            }
        }, 30);
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting && !hasAnimated) {
                animateCountUp(setExperience, 15);
                animateCountUp(setTeam, 20);
                animateCountUp(setCustomer, 100);
                animateCountUp(setReviews, 50);
                setHasAnimated(true);
            }
        }, {
            threshold: 0.1, // Start the animation when 10% of the component is visible
        });

        if (statRef.current) {
            observer.observe(statRef.current);
        }

        return () => {
            if (statRef.current) {
                observer.unobserve(statRef.current);
            }
        };
    }, [hasAnimated]);

    return (
        <div ref={statRef} className="lg:m-20 m-8 bg-green-900 rounded-lg md:p-8">
            <dl className="grid lg:grid-cols-3 gap-8 p-4 mx-auto text-white sm:grid-cols-3 xl:grid-cols-4 sm:p-8">
                <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{experience}+</dt>
                    <dd className="text-gray-300">Experience (years)</dd>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{team}+</dt>
                    <dd className="text-gray-300">Team Members</dd>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{customer}+</dt>
                    <dd className="text-gray-300">Customers</dd>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{reviews}+</dt>
                    <dd className="text-gray-300">Positive Reviews</dd>
                </div>
            </dl>
        </div>
    );
}

export default Stat;
