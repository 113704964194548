import React, {useEffect} from 'react'
import main_img from '../images/img2.jpg'
import Aos from 'aos'
import 'aos/dist/aos.css'

function Hero() {

    useEffect(() => {
        Aos.init({duration: 2000})
    },[])

    return (
        <div id='home'>
            <img src={main_img} alt='' className='h-screen w-screen object-cover absolute' />
            <div className='lg:px-0 px-4  h-screen w-screen flex flex-col justify-center items-center relative bg-black bg-opacity-30 text-white'>
                <h1 className='my-8 text-2xl '>لَبَّيْكَ اللَّهُمَّ لَبَّيْك</h1>
                <p className='lg:text-5xl text-3xl text-center font-extrabold ' data-aos = 'fade-up' >Umrah with Ease, Faith with Peace</p>
                <a href='https://forms.gle/cSLXhSZ7pNFErPPp9' target='blank' data-aos = 'flip-right' className='w-fit px-10 py-3 bg-[#bf9b30] my-12 rounded-full font-bold text-lg hover:shadow-md hover:scale-105 hover:transition-all hover:duration-300 '>Get started</a>
            </div>
        </div>
    )
}

export default Hero
