import React, { useState, useEffect } from 'react';
import Logo from '../images/jaffri_Logo.png';
import { Link, useNavigate } from 'react-router-dom';

function Navbar() {
    const [scrollNav, setScrollNav] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [navItem, setNavitem] = useState('home')
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setScrollNav(window.scrollY > 10);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const handleScrollToSection = (sectionId) => {
        setIsMenuOpen(false);
        navigate('/'); 
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    };

    return (
        <nav
            className={`flex flex-col lg:flex-row md:flex-row justify-between my-2 mx-4 lg::mx-20 md:mx-8 px-4 md:px-10 py-2 bg-opacity-80 lg:rounded-full md:rounded-full rounded-lg 
            ${scrollNav ? 'bg-white text-black backdrop-blur-sm transition-all duration-200' : 'text-gray-400'}
            `}
        >
            <div className='flex items-center justify-between w-full md:w-auto'>
                <img className='h-16 lg:h-24' src={Logo} alt='Jaffry Tours & Travels Logo' />
                <div className='my-auto font-serif hidden lg:flex flex-col'>
                    <h1 className='font-bold text-lg'>Jaffry Tours & Travels</h1>
                    <p className='text-gray-600'>Book your dream travel</p>
                </div>
                <button
                    className='lg:hidden md:hidden p-2'
                    onClick={toggleMenu}
                >
                    <svg
                        className='w-6 h-6'
                        fill='none'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4 6h16M4 12h16m-7 6h7' />
                    </svg>
                </button>
            </div>

            <div className={`flex-col lg:mt-0 md:mt-0 mt-4 lg:flex-row md:flex-row lg:flex md:flex lg:items-center lg:gap-8 ${isMenuOpen ? 'flex' : 'hidden'} lg:flex`}>
                <div className='flex flex-col lg:flex-row md:flex-row lg:gap-8 gap-4 text-xl lg:font-semibold  md:my-auto md:font-semibold font-medium'>
                    <Link
                    className={`${navItem === 'home' && 'text-[#bf9b30] font-bold '}`}
                        onClick={() => {
                            setIsMenuOpen(false)
                            setNavitem('home')
                        }}
                        to={'/'}>
                        Home
                    </Link>
                    <a
                    className={`${navItem === 'about' && 'text-[#bf9b30] font-bold '}`}
                        href='#about'
                        onClick={() => {
                            handleScrollToSection('about')
                            setNavitem('about')
                        }}>
                        About
                    </a>
                    <a
                    className={`${navItem === 'services' && 'text-[#bf9b30] font-bold '}`}
                        href='#services'
                        onClick={() => {
                            handleScrollToSection('services')
                            setNavitem('services')
                        }}>
                        Services
                    </a>

                    <a
                    className={`${navItem === 'packages' && 'text-[#bf9b30] font-bold '}`} href='#packages'
                        onClick={() => {
                            handleScrollToSection('packages')
                            setNavitem('packages')
                        }}>
                        Packages
                    </a>

                    <a
                    className={`${navItem === 'Offices' && 'text-[#bf9b30] font-bold '}`}
                        href='#Offices'
                        onClick={() => {
                            handleScrollToSection('Offices')
                            setNavitem('Offices')
                        }}>
                        Offices
                    </a>

                    <Link
                    className={`${navItem === 'contact' && 'text-[#bf9b30] font-bold '}`}
                        onClick={() => {
                            setIsMenuOpen(false)
                            setNavitem('contact')
                        }}
                        to={'/contact'}>
                        Contact
                    </Link>
                </div>
            </div>

            {/* "Book Now" button visible only on larger screens */}
            <div className='hidden lg:flex my-auto'>
                <a href='https://forms.gle/cSLXhSZ7pNFErPPp9' target='blank' className='w-fit bg-[#bf9b30] hover:shadow-md hover:scale-105 hover:transition-all hover:duration-300 text-white text-lg font-bold px-10 py-2 rounded-lg'>
                    Book Now
                </a>
            </div>
        </nav>
    );
}

export default Navbar;
