import React, {useEffect} from 'react'
import visa from '../images/visa.png'
import assist from '../images/operator.png'
import airport from '../images/car.png'
import carRent from '../images/car-rent.png'
import hotel from '../images/review.png'
import guidedTour from '../images/tour.png'
import catering from '../images/catering.png'
import charityGifts from '../images/gift.png'
import bestTravel from '../images/travel-and-tourism.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

function GroundServices() {

    useEffect(() => {
        Aos.init({duration: 2000})
    },[])

    return (
        <div id='services' className='lg:p-20 p-8 bg-gray-100 ' >
            <h1 className='text-2xl text-[#bf9b30] font-bold ' >What we Provide </h1>
            <p className='text-xl font-medium my-4 lg:w-1/2 ' >We provide our valuable customers the best services to easily perform their <span className='text-[#bf9b30] font-bold' >Umrah & Hajj</span> tours.</p>
            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:px-20 py-10' data-aos = 'fade-right' >

                <div className='p-8 w-full lg:border-r-2 md:border-r-2 border-b-2 border-gray-400'>
                    <img src={visa} alt='' className='h-20' />
                    <h1 className='my-4 text-2xl font-bold'>Visa Support</h1>
                    <p className='text-gray-700' >Streamlined visa processing to ensure a hassle-free journey.</p>
                </div>

                <div className='p-8 w-full lg:border-r-2 border-b-2 border-gray-400'>
                    <img src={assist} alt='' className='h-20' />
                    <h1 className='my-4 text-2xl font-bold'>Meet & Assist</h1>
                    <p className='text-gray-700'>Personalized assistance from arrival to departure.</p>
                </div>

                <div  className='p-8 w-full border-b-2 md:border-r-2 lg:border-r-0 border-gray-400' >
                    <img src={airport} alt='' className='h-20 ' />
                    <h1 className='my-4 text-2xl font-bold'>Airport Transfers</h1>
                    <p className='text-gray-700'>Comfortable and reliable airport pick-up and drop-off.</p>
                </div>

                <div className='p-8 w-full lg:border-r-2 border-b-2 border-gray-400'>
                    <img src={carRent} alt='' className='h-20' />
                    <h1 className='my-4 text-2xl font-bold'>Car Rentals</h1>
                    <p className='text-gray-700'>Flexible car rental services to suit your travel needs.</p>
                </div>

                <div className='p-8 w-full lg:border-r-2 md:border-r-2 border-b-2 border-gray-400'>
                    <img src={hotel} alt='' className='h-20' />
                    <h1 className='my-4 text-2xl font-bold'>Hotel Accommodations</h1>
                    <p className='text-gray-700'>Comfortable and convenient hotel stays at prime locations.</p>
                </div>

                <div  className='p-8 w-full border-b-2 border-gray-400' >
                    <img src={guidedTour} alt='' className='h-20' />
                    <h1 className='my-4 text-2xl font-bold'>Guided Tours</h1>
                    <p className='text-gray-700'>Expert-guided tours to enhance your spiritual experience.</p>
                </div>

                <div className='p-8 w-full lg:border-r-2 md:border-r-2 lg:border-b-0 border-b-2 border-gray-400'>
                    <img src={catering} alt='' className='h-20' />
                    <h1 className='my-4 text-2xl font-bold'>Quality Catering Service</h1>
                    <p className='text-gray-700'>Delicious and hygienic meals tailored to your preferences.</p>
                </div>

                <div className='p-8 w-full lg:border-r-2 lg:border-b-0 border-b-2 border-gray-400'>
                    <img src={charityGifts} alt='' className='h-20' />
                    <h1 className='my-4 text-2xl font-bold'>Charity Gift Arrangement</h1>
                    <p className='text-gray-700'>Thoughtful charity gift arrangements to share your blessings.</p>
                </div>

                <div className='p-8 w-full ' >
                    <img src={bestTravel} alt='' className='h-20' />
                    <h1 className='my-4 text-2xl font-bold'>Best Travel</h1>
                    <p className='text-gray-700'>Exceptional travel planning for a worry-free Umrah experience.</p>
                </div>
            </div>
        </div>
    )
}

export default GroundServices
