import React, { useState, useRef, useEffect } from 'react';
import contactImg from '../images/img3.jpg';
import { BsTelephoneFill } from "react-icons/bs";
import { MdMarkEmailRead } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

function Contact({ packageName }) {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    const [selectedPackage, setSelectedPackage] = useState(packageName || '');
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const messageRef = useRef(null);

    const handlePackageChange = (e) => {
        setSelectedPackage(e.target.value);
    };

    document.title = 'Contact - Jaffry Travels & Tours';

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const handleScrollToSection = (sectionId) => {
        navigate('/');
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    };


    return (
        <div className={`lg:mt-40 md:mt-40 mt-28 overflow-x-hidden ${loaded ? 'visible' : 'invisible'}`}>
            <div className='lg:px-20 px-4 my-10'>
                <div data-aos='zoom-in' className='bg-gradient-to-r from-[#d7c694] to-green-300 hover:bg-gradient-to-t flex justify-center items-center w-full h-96 rounded-lg'>
                    <div className='flex flex-col justify-center items-center'>
                        <h1 className='border-black rounded-full px-12 py-2 text-4xl font-bold w-fit'>Contact Us</h1>
                        <p className='lg:w-2/3 md:w-2/3 lg:px-0 px-4 text-center my-8'>It's great that you reached out to us! We pride ourselves on providing the best service. Thank you for choosing us!</p>
                    </div>
                </div>
            </div>

            <form data-aos='fade-right' action="https://api.web3forms.com/submit" method="POST">
                <input type="hidden" name="access_key" value="80664862-90eb-4a9c-9eef-d2a3b5a60289" />
                <input type="hidden" name="redirect" value="http://localhost:3000" />
                <section className="">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
                            <div className="lg:mb-0 mb-10">
                                <div className="group w-full h-full">
                                    <div className="relative h-full">
                                        <img src={contactImg} alt="ContactUs tailwind section" className="w-full lg:rounded-l-2xl rounded-2xl bg-blend-multiply bg-indigo-700" />
                                        <h1 className="font-manrope text-[#bf9b30] text-4xl font-bold leading-10 absolute top-11 left-11">Contact us</h1>
                                        <div className="absolute bottom-0 w-full lg:p-11 p-5">
                                            <div className="bg-white rounded-lg p-6 block">
                                                <a href="/" className="flex items-center mb-6">
                                                    <BsTelephoneFill color='#bf9b30' size={25} className='my-auto' />
                                                    <h5 className="text-black text-base font-normal leading-6 ml-5">+966 7424 379932</h5>
                                                </a>
                                                <a href="https://veilmail.io/irish-geoff" className="flex items-center mb-6">
                                                    <MdMarkEmailRead color='#bf9b30' size={25} className='my-auto' />
                                                    <h5 className="text-black text-base font-normal leading-6 ml-5">jaffry.travel.umrah@gmail.com</h5>
                                                </a>
                                                <a href="/" className="flex items-center">
                                                    <FaLocationDot color='#bf9b30' size={25} className='my-auto' />
                                                    <h5 className="text-black text-base font-normal leading-6 ml-5">Hussein AlArab Street, AlZahra, Makkah</h5>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-gray-50 p-5 lg:p-11 lg:rounded-r-2xl rounded-2xl">
                                <h2 className="text-[#bf9b30] font-manrope text-4xl font-semibold leading-10 mb-11">Send Us A Message</h2>
                                <input name='Name' required type="text" ref={nameRef} className="w-full h-12 text-gray-600 placeholder-gray-400 shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10" placeholder="Name" />
                                <input name='Email' required type="email" ref={emailRef} className="w-full h-12 text-gray-600 placeholder-gray-400 shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10" placeholder="Email" />
                                <input name='PhoneNo' required type="number" ref={phoneRef} className="w-full h-12 text-gray-600 placeholder-gray-400 shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10" placeholder="Phone" />
                                <div className="mb-10">
                                    <h4 className="text-gray-500 text-lg font-normal leading-7 mb-4">
                                        <a
                                            href='#packages'
                                            className='hover:underline hover:text-[#bf9b30]'
                                            onClick={() => handleScrollToSection('packages')}
                                        >Packages</a> / optional
                                    </h4>
                                    <div className="lg:flex">
                                        <div className="flex items-center mr-11">
                                            <input
                                                id="radio-group-1"
                                                type="radio"
                                                name="package"
                                                value="economy"
                                                className="checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100"
                                                checked={selectedPackage === 'economy'}
                                                onChange={handlePackageChange}
                                            />
                                            <label
                                                htmlFor="radio-group-1"
                                                className="flex ml-4 items-center cursor-pointer text-gray-500 text-base font-normal leading-6"
                                            >
                                                Economy
                                            </label>
                                        </div>
                                        <div className="flex items-center mr-11">
                                            <input
                                                id="radio-group-2"
                                                type="radio"
                                                name="package"
                                                value="standard"
                                                className="checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100"
                                                checked={selectedPackage === 'standard'}
                                                onChange={handlePackageChange}
                                            />
                                            <label
                                                htmlFor="radio-group-2"
                                                className="flex ml-4 items-center cursor-pointer text-gray-500 text-base font-normal leading-6"
                                            >
                                                Standard
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id="radio-group-3"
                                                type="radio"
                                                name="package"
                                                value="premium"
                                                className="checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100"
                                                checked={selectedPackage === 'premium'}
                                                onChange={handlePackageChange}
                                            />
                                            <label
                                                htmlFor="radio-group-3"
                                                className="flex ml-4 items-center cursor-pointer text-gray-500 text-base font-normal leading-6"
                                            >
                                                Premium
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <input name='Message' type="text" ref={messageRef} className="w-full h-12 text-gray-600 placeholder-gray-400 bg-transparent text-lg shadow-sm font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10" placeholder="Message" />
                                <button type="submit" className="w-full h-12 text-white text-base font-semibold leading-6 rounded-full transition-all duration-700 hover:bg-[#aa8c3b] bg-[#bf9b30] shadow-sm">Send</button>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    );
}

export default Contact;
