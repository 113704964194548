import React from 'react'
import Hero from '../components/hero'
import About from '../components/about'
import Who from '../components/who'
import GroundServices from '../components/groundServices'
import Chooseus from '../components/chooseus'
import Stat from '../components/stats'
import Packages from '../components/packages'
import Offices from '../components/offices'

function Home() {

    document.title = 'Home - Jaffry Travels & Tours'

    return (
        <div className='overflow-x-hidden' >
            <Hero />
            <About />
            <Who />
            <Stat />
            <GroundServices />
            <Packages />
            <Offices />
            <Chooseus />
        </div>
    )
}

export default Home
