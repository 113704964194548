import React, {useEffect} from 'react'
import member1 from '../images/saidi_arabia.png'
import member2 from '../images/pakistan.svg'
import member3 from '../images/pakistan.svg'
import member4 from '../images/uk.webp'
import { FaLocationDot } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import Aos from 'aos'
import 'aos/dist/aos.css'


function Offices() {

    useEffect(() => {
        Aos.init({duration: 2000})
    },[])

    const TeamMembers = [
        {
            Name: 'Nadeem Abbas',
            ProfileImage: member1,
            Designation: 'Founder',
            Location: 'Saudi Arabia',
            whatsapp: '966534924683',
            email: ''
        },
        {
            Name: 'Hassan Raza',
            ProfileImage: member2,
            Designation: 'Consultant',
            Location: 'Pakistan',
            whatsapp: '923179982448',
            email: ''
        },
        {
            Name: 'Faheem Abbas',
            ProfileImage: member3,
            Designation: 'Consultant',
            Location: 'Pakistan',
            whatsapp: '923456897447',
            email: ''
        },
        {
            Name: 'Waseem Shah',
            ProfileImage: member4,
            Designation: 'Consultant',
            Location: 'United Kingdom',
            whatsapp: '447424379932',
            email: ''
        },
    ]

    return (
        <section id='Offices' className="bg-gray-100">
            <div className="container lg:p-20 p-8 mx-auto">
                <h1 className="text-2xl font-semibold text-center text-black capitalize lg:text-3xl">Our Executive Offices</h1>

                <p className="max-w-2xl mx-auto my-6 text-center text-black ">
                    At Jaffry Travels, our dedicated team of professionals ensures your Umrah and Hajj experiences are smooth, comfortable, and spiritually fulfilling. We're committed to providing premium service and support every step of the way.
                </p>

                <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-4" data-aos = 'fade-left' >

                    {
                        TeamMembers.map((member, index) => (
                            <div key={index} className="flex flex-col items-center p-8 transition-colors duration-300 transform cursor-pointer group hover:bg-green-900 rounded-xl">
                                <img className="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300" src={member.ProfileImage} alt={member.Name} />

                                <div className='mt-4 flex flex-col gap-y-2 justify-center items-center ' >
                                    <h1 className="text-2xl font-semibold text-gray-900 capitalize group-hover:text-white">{member.Name}</h1>

                                    <a href="/" className="text-gray-600 transition-colors duration-300 group-hover:text-white text-sm hover:underline flex gap-2"><FaLocationDot color='#bf9b30' size={20} className='my-auto' />{member.Location}</a>

                                    <div className='flex gap-2' >
                                        <a href={`https://api.whatsapp.com/send?phone=${member.whatsapp}&text=Hello,%20How%20are%20you%3F`}   ><IoLogoWhatsapp color='#bf9b30' size={20} className='group-hover:text-white' /></a>
                                        <a href={`mailto:${member.email}`} ><MdEmail color='#bf9b30' size={20} className='group-hover:text-white' /></a>
                                    </div>
                                </div>

                            </div>
                        ))
                    }

                </div>
            </div>
        </section>
    )
}

export default Offices