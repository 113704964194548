import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'



function Chooseus() {

  useEffect(() => {
    Aos.init({duration: 2000})
},[])

  return (
    <div className='lg:p-20 p-8' >
        <div data-aos = 'zoom-in' className='bg-gradient-to-r from-[#d7c694] to-green-300 hover:bg-gradient-to-t flex justify-center items-center w-full h-96 rounded-lg' >
            <div className='flex flex-col justify-center items-center'>
                <h1 className=' border-black rounded-full px-12 py-2  text-2xl font-bold w-fit text-center ' >#Why Choose Us</h1>
                <p className='lg:w-2/3 text-center lg:text-base text-sm lg:mx-0 mx-4 lg:my-8 my-4' >With 15 years of expertise, Jaffry Travel & Tours offers exceptional Umrah services, ensuring a seamless and spiritually enriching journey. From visa processing to transportation, our experienced team handles every detail with care, providing personalized service, comfort, and peace of mind for your spiritual pilgrimage. </p>
                <Link to={'/contact'} className='text-lg font-semibold border-2 border-[#bf9b30]  px-12 py-2 rounded-full hover:bg-[#bf9b30] hover:text-white transition-all duration-300' >Contact Us</Link >
            </div>
        </div>
      
    </div>
  )
}

export default Chooseus
